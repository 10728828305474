import * as React from "react";
import {
  Row,
  Col,
  Space,
  Typography,
  Form,
  Select,
  Divider,
  Button,
  Dropdown,
  DatePicker,
  
} from "antd";
import {
  PlusOutlined,
  FilterOutlined,
  DownloadOutlined,
  ExportOutlined,
  BackwardOutlined,
} from "@ant-design/icons";

import Search from "antd/es/input/Search";
import { useNavigate } from "react-router-dom";
const { Title, Text } = Typography;
var _ = require("lodash");

export default (props) => {
  const {
    heading,
    total,
    importAction,
    filterAction,
    addNewAction,
    dropNewAction,
    dropItems,
    dropTrigger,
    addMoreButton,
    exportAction,
    back,
    searchOptions,
    setSearchText,
    setSearchBy,
    searchingBy,
    PaymentModeOptions,
    organizationList,
    paymentReports,
    setOrgId,
    setPaymentType,
    setStartDate,
    setEndDate,
    setExportReport,
    onClear
  } = props;
  const navigate = useNavigate();

  const searchDropdown =
    searchOptions?.length > 0 ? (
      <Select
        bordered={false}
        placeholder={searchingBy ? searchingBy : `Select`}
        style={{ minWidth: 120, border: 0, borderRadius: 0 }}
        onChange={(e: any) => {
          setSearchBy(e)
        }}
        size="large"
        dropdownMatchSelectWidth={false}
        options={searchOptions}
      />
    ) : (
      false
    );


  //  date range picker 
  const { RangePicker } = DatePicker;

  const onDateChange = (dates) => {
    if (dates) {
      setStartDate(dates[0].format('YYYY-MM-DD'));
      setEndDate(dates[1].format('YYYY-MM-DD'));
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  return (
    <Row gutter={[3, 7]}>
      <Col span={10}>
        <Space size={8} className="h-100">


          <Title
            level={2}
            className={`custom-title32 ${total ? "borderRight" : ""}`}
          >
            {heading}
          </Title>
          { (
            <Title level={5} className="mb-0">
              {total} Total
            </Title>
          )}
        </Space>
      </Col>



      <Col span={14}>
        <div style={{ display: 'flex', justifyContent: 'end' }}>

          {
            importAction &&
            <Button
              size="large"
              icon={<DownloadOutlined />}
              onClick={importAction}
              className="property_card_btn"
            >
              Import
            </Button>
          }

          {filterAction && (

            <Button
              size="large"
              icon={<FilterOutlined />}
              onClick={filterAction}
              className="property_card_btn"
            >
              Filters
            </Button>

          )}

          {addNewAction && (
            <Button
              size="large"
              type="primary"
              onClick={addNewAction}
              className="property_card_btn"
            >
              Add New {props.addText && props.addText} <PlusOutlined />
            </Button>
          )}

          {dropNewAction && (

            <Dropdown
              menu={{ items: dropItems, onClick: dropNewAction }}
              trigger={dropTrigger}
            >
              <Button
                size="large"
                type="primary"
                className="property_card_btn"
                onClick={(e) => e.preventDefault()}
              >
                Add New {props.addText && props.addText} <PlusOutlined />
              </Button>
            </Dropdown>

          )}

          {addMoreButton && (

            <Button
              size="large"
              type="primary"
              onClick={addMoreButton}
              className="property_card_btn"
            >
              Add New {props.addMoreText && props.addMoreText} <PlusOutlined />
            </Button>

          )}
          {exportAction && (


            <Button
              size="large"
              type="primary"
              className="property_card_btn"
              icon={<ExportOutlined />}
              onClick={()=>setExportReport(true)}
            >
              Export
            </Button>


          )}
          {back && (

            <Button
              style={{ marginRight: '25px' }}
              size="large"
              icon={<BackwardOutlined />}
              onClick={() => navigate(back)}
            >
              Back
            </Button>

          )}
        </div>
      </Col>



      <Col span={24} style={{ paddingLeft: 0 }}>
        <Form
          className="ag-filter-bar"
          name="property_filter"
          layout="inline"
        >
          {
          
            <>

              <Col span={PaymentModeOptions?.length > 0 ? 18 : 15} className={(paymentReports || searchOptions?.length>0) ? 'visible' : 'sectionHeaderVisibilty'}>
                <div style={{ display: 'flex' }}>
                  <div className="payment-reports-section" style={{ flexGrow: 1 }}>
                  <small style={{ marginBottom: '2px', color: 'gray' }}>Search by</small>
                    <Form.Item className="search">

                      <Search
                        addonBefore={searchDropdown}
                        className="org_search"
                        placeholder={"Search..."} 
                        enterButton
                        bordered={false}
                        size="large"
                        disabled={!searchingBy}
                        allowClear
                        onSearch={(e) => setSearchText(e)}
                      />
                    </Form.Item>

                  </div>

                 { paymentReports && <div>
                    <div style={{ marginLeft: 15 }}>
                      <small style={{ marginBottom: '2px', color: 'gray', }}>Date</small>
                      <div>
                        <RangePicker style={{ height: '41px', borderRadius: '6px' }} onChange={onDateChange} />
                      </div>
                    </div>
                  </div>}
                 {PaymentModeOptions?.length > 0 && <div>
                    <div style={{ marginLeft: 15 }}>
                      <small style={{ marginBottom: '2px', color: 'gray' }}>Payment Types</small>
                      <Form.Item name="payment_mode_types">

                        <Select
                          placeholder={'All'}
                          bordered={true}
                          labelInValue
                          onChange={(e)=>setPaymentType(e.value)}
                          size="large"
                          options={PaymentModeOptions}
                          style={{ background: '#FFFFF',width:'220px' }}

                        />
                      </Form.Item>
                    </div>
                  </div>}

                

                </div>
              </Col>

            </>
          }


          {
            (organizationList?.length > 0) &&


            <Col className={organizationList?.length > 0 ? 'visible' : 'sectionHeaderVisibilty'} span={3}>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <div>
                  <small style={{ marginBottom: '2px', color: 'gray' }}>Orgnization ({organizationList?.length}) </small>
                  <Form.Item
                    name="view"
                    initialValue={{ value: "All Orgnizations" }}
                  >
                    <Select
                      bordered={true}
                      allowClear={true}
                      labelInValue
                      showSearch
                      onChange={(e)=>setOrgId(e.value)}
                      // onChange={(e)=>console.log(e)}
                      filterOption={(input:any, option:any) => {
                        return (option?.label ?? '')?.toLowerCase().includes(input.toLowerCase())
                    }}
                      size="large"
                         options={
                             organizationList?.map((item)=>{
                                 return {
                                  value:item?.orgId,
                                  label:item?.name
                                 }
                             })
                           }
                      style={{ background: '#FFFFF', marginTop: '1px', width: '250px' }}

                    />
                  </Form.Item>
                </div>




              </div>
            </Col>
          }
        </Form>
      </Col>



      {!props.noborder && <Divider className="mt-0 ag-border-cold9" />}
    </Row>
  );
};
