import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Spin,
  Space,
  Button,
  Divider,
  message,
  Typography,
  Modal, 
  Upload
} from "antd";

import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import axios from "../../../../utils/axiosInceptor";
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import { disabledEndDate } from "../../../../utils/helper";
import { adminService } from "../../../../configs/constants";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { BsPlusCircle, BsArrowCounterclockwise } from "react-icons/bs";
import { DateField, InputField, SelectField } from "../../../atoms/FormElement";

const { Paragraph, Text } = Typography;

const defaultValues: any = {
  name: "",
};

var _ = require("lodash");

export default (props) => {
  const { onUpdate, onchangeUpdate } = props;
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [endDate, setEndDate] = useState(dayjs());
  const [startDate, setStartDate] = useState(dayjs());
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues,
  });

  const subscription = useSelector(
    (state: any) => state.subscription.getAllSubscriptions
  );

  const  emiratesList  = useSelector(
    (state: any) => state?.auth?.configData
  )?.[0];


  let emiratesListData = emiratesList ? emiratesList : [];
  useEffect(() => {
     ( async () => {
      if(props?.selected?.logo) {
        setLoader(true)
        const url = `${adminService}/document/getDocument/${props?.selected?.logo}`;

        try {
          const response = await axios.get(url, { headers: { application: 4 } })
          let { data } = response;
        
          setFileList([{
            uid: '-1',
            name: 'Logo',
            status: 'done',
            url: data?.result?.location
          }])
          setLoader(false);
        } catch (error) {
          setLoader(false);
        }
      }
    })()

    return () => {
      setFileList([])
      reset();
    };
  }, [props]);

  useEffect(() => {
    if (props.selected) _setSateValues(props.modalContent);
    else reset();
  }, [props.selected, subscription, props]);

  const _setSateValues = (type: string) => {
    switch (type) {
      case "USER":
        setValue("name", props.selected.name);
        setValue("orgCode", props.selected?.orgCode || "XXXX");
        setValue("userName", props.selected?.user?.name);
        setValue("userEmail", props.selected.user?.email);
        setValue("userCountryCode", props.selected.user?.countryCode);
        setValue("userMobile", props.selected.user?.mobile);
        break;
      case "EDIT_BU":
        console.log(props);         
        setValue("buName", props.selected.businessUnitName);
        setValue("buContactName", props.selected.contactPersonName);
        setValue("buContactEmail", props.selected.email);
        setValue("buContactMobile", props.selected.mobile);
        setValue("logo", props.selected.logo);
        //Address 
        setValue("addressLine1", props.selected.addressLine1);
        setValue("addressLine2", props.selected.addressLine2);
        setValue("apartment", props.selected.apartment);
        setValue("buildingName", props.selected.buildingName);
        setValue("buildingNumber", props.selected.buildingNumber);
        setValue("floor", props.selected.floor);
        setValue("poBox", props.selected.poBox);
        setValue("floor", props.selected.floor);
        setValue(
          "city",
          _.map(emiratesListData, (val, key) => ({
            value: key,
            label: val,
          })).filter((item) => item.label === props.selected.city)
        );

        setValue("tradeLicenseNumber", props.selected.tradeLicense);
        setValue(
          "emirates",
          _.map(emiratesListData, (val, key) => ({
            value: key,
            label: val,
          })).filter((item) => item.label === props.selected.emirates)
        );
        setStartDate(dayjs(props.selected.startDate));
        setEndDate(dayjs(props.selected.endDate));
        setValue("startDate", dayjs(props.selected.startDate));
        setValue("endDate", dayjs(props.selected.endDate));
        break;
      default:
        break;
    }
  };

  
  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleFileChange = async (event) => {

    if(!(event.type === 'image/png' || event.type === 'image/jpg' || event.type === 'image/jpeg')) {
      message.error(
        'Please upload png or jpeg images only'
      );
      setLoader(false);
    }

    if(event) {
      let file = event;
      let url = `${adminService}/document/uploadFile`;
  
      const postJson = {
        file_asset: file,
      };
      
      try {
        setLoader(true);
        const fileUpload = await axios.post(url, postJson, { headers: { 'Content-Type': 'multipart/form-data' } })
  
        if(fileUpload?.data?.result?.document) {
          setValue("logo", fileUpload?.data?.result?.documentId);
          setLoader(false);
        }
      } catch (e) {
        setErrorMessage(e)
        setLoader(false);
      }
    }
  }
  
  const onFinish = (values) => {
    props.onSubmit();
    reset();
    setLoader(true);
    setSubmited(true);

    let URL = "";
    let data = {};
    let method = "";

    switch (props.modalContent) {
      case "ALL":
        method = "POST";
        URL = `${adminService}/organization`;
        data = {
          name: values.name,
          orgCode: values.orgCode,
          userName: values.userName,
          userEmail: values.userEmail,
          userCountryCode: "+971",
          userMobile: values.userMobile,
          addressLine1: values.addressLine1,
          addressLine2: values.addressLine2,
          city: values?.city?.label,
          poBox: values.poBox,
          buildingNumber: values.buildingNumber,
          buildingName: values.buildingName,
          floor: values.floor,
          apartment: values.apartment,
          buName: values.buName,
          buContactName: values.buContactName,
          buContactEmail: values.buContactEmail,
          buContactMobile: values.buContactMobile,
          tradeLicenseNumber: values.tradeLicenseNumber,
          emirates: values?.emirates?.label,
          startDate: values.startDate,
          endDate: values.endDate,
          logo: values.logo
        };
        break;
      case "USER":
        method = "PATCH";
        URL = `${adminService}/organization/${props.selected.orgId}`;
        data = {
          userName: values.userName,
        };
        break;
      case "BU":
        method = "POST";
        URL = `${adminService}/businessUnit`;
        data = {
          businessUnitName: values.buName,
          contactPersonName: values.buContactName,
          email: values.buContactEmail,
          mobile: values.buContactMobile,
          addressLine1: values.addressLine1,
          addressLine2: values.addressLine2,
          city: values?.city?.label,
          poBox: values.poBox,
          buildingNumber: values.buildingNumber,
          buildingName: values.buildingName,
          floor: values.floor,
          apartment: values.apartment,
          tradeLicense: values.tradeLicenseNumber,
          emirates: values?.emirates?.label,
          startDate: values.startDate,
          endDate: values.endDate,
          orgId: props.selected.orgId,
          logo: values.logo
        };
        break;
      case "EDIT_BU":
        method = "PATCH";
        URL = `${adminService}/businessUnit/${props.selected.businessUnitId}`;
        data = {
          contactPersonName: values.buContactName,
          addressLine1: values.addressLine1,
          addressLine2: values.addressLine2,
          city: values?.city?.label,
          poBox: values.poBox,
          buildingNumber: values.buildingNumber,
          buildingName: values.buildingName,
          floor: values.floor,
          apartment: values.apartment,

          tradeLicense: values.tradeLicenseNumber,
          emirates: values?.emirates?.label,
          startDate: values.startDate,
          endDate: values.endDate,
          logo: values.logo ? values.logo : null
        };
        break;
      default:
        break;
    }

    _handleRequest(URL, data, method);
  };

  const _handleRequest = async (url: string, data: object, method) => {
    axios({
      url,
      method,
      data,
      headers: { application: 4 },
    })
      .then((response) => {
        setLoader(false);
        setSubmited(false);
        reset();
        message.success(response.data.message);
        onUpdate(!onchangeUpdate);
        props.onSubmit();
      })
      .catch((error) => {
        message.error(
          error.response.data?.error || error.response.data.message
        );
        setErrorMessage(error.response.data?.error || error.response.data?.message)
        setLoader(false);
        setSubmited(false);
        setisError(true);
        reset();
      });
  };

  const _handle_ButtonLabel = (type: string) => {
    let label = "";
    switch (type) {
      case "ALL":
        label = "Add";
        break;
      case "USER":
        label = "Update";
        break;
      case "BU":
        label = "Add";
        break;
      case "EDIT_BU":
        label = "Update";
        break;
      default:
        break;
    }

    return label;
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  return (
    <Form
      layout="vertical"
      className="bu_o_form"
      onFinish={handleSubmit(onFinish)}
    >
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          {["ALL", "USER"].includes(props.modalContent) && (
            <>
              <Row gutter={24}>
                <Col span={12}>
                  <InputField
                    fieldname="name"
                    control={control}
                    isRequired={true}
                    label="Organization Name"
                    validate={errors.name && "error"}
                    validMessage={errors.name && errors.name.message}
                    rules={{
                      required: "Please enter organization name",
                    }}
                    iProps={{
                      placeholder: "Enter name",
                      disabled: props.selected?.orgId ? true : false,
                    }}
                  />
                </Col>

                <Col span={12}>
                  <InputField
                    isRequired={true}
                    control={control}
                    fieldname="orgCode"
                    label="Organization Code"
                    validate={errors.orgCode && "error"}
                    validMessage={errors.orgCode && errors.orgCode.message}
                    onChange={(e) =>
                      setValue("orgCode", e.target.value.toUpperCase())
                    }
                    rules={{
                      maxLength: {
                        value: 5,
                        message: "Code length should be less or equal to 5.",
                      },
                      pattern: new RegExp("^[a-zA-Z0-9]+$"),
                      required: "Please enter organization code",
                    }}
                    iProps={{
                      disabled: props.editMode,
                      className: "text-uppercase",
                      placeholder: "Enter organization code",
                    }}
                  />
                </Col>
              </Row>

              <Divider />

              {/* User Details */}
              <h3>User Details</h3>

              <Row gutter={24}>
                <Col span={12}>
                  <InputField
                    label="User Name"
                    isRequired={true}
                    control={control}
                    fieldname="userName"
                    validate={errors.userName && "error"}
                    iProps={{ placeholder: "Enter Full Name" }}
                    validMessage={errors.userName && errors.userName.message}
                    rules={{
                      required: "Please enter full name",
                    }}
                  />
                </Col>

                <Col span={12}>
                  <InputField
                    label="User Email"
                    isRequired={true}
                    fieldname="userEmail"
                    control={control}
                    validate={errors.userEmail && "error"}
                    validMessage={errors.userEmail && errors.userEmail.message}
                    iProps={{
                      placeholder: "user@gmail.com",
                      disabled: props.editMode
                        ? props.selected?.user?.email
                          ? true
                          : false
                        : false,
                    }}
                    rules={{
                      pattern: {
                        value: /(?=^.{1,50}$)^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                        message: "Enter valid Email Address",
                      },
                      required: "Please enter Email Address",
                    }}
                  />
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={12}>
                  <InputField
                    isRequired={true}
                    control={control}
                    fieldname="userMobile"
                    label="User Mobile Number"
                    validate={errors.userMobile && "error"}
                    validMessage={
                      errors.userMobile && errors.userMobile.message
                    }
                    iProps={{
                      type: "number",
                      addonBefore: "+971",
                      className: "apperance",
                      placeholder: "Add Mobile Number",
                      disabled: props.editMode
                        ? props.selected?.user?.email
                          ? true
                          : false
                        : false,
                    }}
                    rules={{
                      minLength: {
                        value: 9,
                        message:
                          "Please enter valid mobile number, 9 digits required",
                      },
                      maxLength: {
                        value: 10,
                        message: "Phone number can not be more than 10 digits",
                      },
                      required: "Please enter Mobile No.",
                      setValueAs: (value) => `${value}`,
                    }}
                  />
                </Col>
              </Row>
            </>
          )}

          {/* Business unit details */}
          {["BU", "ALL", "EDIT_BU"].includes(props?.modalContent) && (
            <>
              {props?.modalContent === "ALL" && <Divider />}

              <h3>Business Unit Details</h3>
              <Row gutter={24}>
                <Col span={12}>
                  <InputField
                    control={control}
                    isRequired={true}
                    fieldname="buName"
                    label="Business Unit Name"
                    validate={errors.buName && "error"}
                    iProps={{
                      placeholder: "Enter name",
                      disabled: props.editMode,
                    }}
                    rules={{
                      required: "Please enter business unit name",
                    }}
                    validMessage={errors.buName && errors.buName.message}
                  />
                </Col>

                <Col span={12}>
                  <InputField
                    isRequired={true}
                    control={control}
                    label="Contact Person Name"
                    fieldname="buContactName"
                    validate={errors.buContactName && "error"}
                    iProps={{
                      placeholder: "Enter name",
                    }}
                    rules={{
                      required: "Please enter business unit name",
                    }}
                    validMessage={
                      errors.buContactName && errors.buContactName.message
                    }
                  />
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={12}>
                  <InputField
                    control={control}
                    isRequired={true}
                    fieldname="buContactEmail"
                    label="Contact Person Email"
                    validate={errors.buContactEmail && "error"}
                    validMessage={
                      errors.buContactEmail && errors.buContactEmail.message
                    }
                    iProps={{
                      disabled: props.editMode,
                      placeholder: "Enter email",
                    }}
                    rules={{
                      pattern: {
                        value: /(?=^.{1,50}$)^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                        message: "Enter valid Email Address",
                      },
                      required: "Please enter Email Address",
                    }}
                  />
                </Col>

                <Col span={12}>
                  <InputField
                    isRequired={true}
                    control={control}
                    fieldname="buContactMobile"
                    validate={errors.buContactMobile && "error"}
                    label="Contact Person Mobile Number"
                    validMessage={
                      errors.buContactMobile && errors.buContactMobile.message
                    }
                    iProps={{
                      type: "number",
                      addonBefore: "+971",
                      className: "apperance",
                      disabled: props.editMode,
                      placeholder: "Add Mobile Number",
                    }}
                    rules={{
                      minLength: {
                        value: 9,
                        message:
                          "Please enter valid mobile number, 9 digits required",
                      },
                      maxLength: {
                        value: 10,
                        message: "Mobile number can not be more than 10 degits",
                      },
                      required: "Please enter mobile no.",
                      setValueAs: (value) => `${value}`,
                    }}
                  />
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={12}>
                  <Row>
                    <Text style={{marginBottom: 8}}>Business Logo</Text>
                  </Row>
                  <Upload
                    action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                    // listType="picture-circle"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    customRequest={({ file, onSuccess }) => {
                      handleFileChange(file)
                      setTimeout(() => {
                        onSuccess('ok');
                      }, 0);
                    }}
                    accept="image/*"
                  >
                    {fileList.length >= 1 ? null : uploadButton}
                  </Upload>
                  <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                  </Modal>
                </Col>
              </Row>

              {/* Address Details */}
              <Divider></Divider>
              <h3>Address Details</h3>
              <Row gutter={24}>
                <Col span={12}>
                  <InputField
                    label="Address Line 1"
                    isRequired={true}
                    control={control}
                    fieldname="addressLine1"
                    validate={errors.addressLine1 && "error"}
                    iProps={{ placeholder: "Enter address" }}
                    validMessage={
                      errors.addressLine1 && errors.addressLine1.message
                    }
                    rules={{
                      required: "Please enter address line 1",
                    }}
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    label="Address Line 2"
                    isRequired={false}
                    control={control}
                    fieldname="addressLine2"
                    iProps={{ placeholder: "Enter address" }}
                  />
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={12}>
                  <SelectField
                    label="Emirate"
                    control={control}
                    isRequired={true}
                    fieldname="city"
                    validate={errors.city && "error"}
                    iProps={{ placeholder: "Select city" }}
                    validMessage={errors.city && errors.city.message}
                    rules={{
                      required: "Please select city",
                    }}
                    selectOption={_.map(emiratesListData, (val, key) => ({
                      value: key,
                      label: val,
                    }))}
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    label="Po Box"
                    isRequired={false}
                    control={control}
                    fieldname="poBox"
                    iProps={{ placeholder: "000000" }}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <InputField
                    label="Building Number"
                    isRequired={false}
                    control={control}
                    fieldname="buildingNumber"
                    iProps={{ placeholder: "001" }}
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    label="Building Name"
                    isRequired={false}
                    control={control}
                    fieldname="buildingName"
                    iProps={{ placeholder: "Business bay" }}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <InputField
                    label="Floor"
                    isRequired={false}
                    control={control}
                    fieldname="floor"
                    iProps={{ placeholder: "1st Floor" }}
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    label="Apartment"
                    isRequired={false}
                    control={control}
                    fieldname="apartment"
                    iProps={{ placeholder: "101" }}
                  />
                </Col>
              </Row>

              <Divider />

              {/* Trade Licenses */}
              <h3>Trade License</h3>

              <Row gutter={24}>
                <Col span={12}>
                  <InputField
                    control={control}
                    isRequired={true}
                    fieldname="tradeLicenseNumber"
                    label="Trade License Number"
                    iProps={{
                      placeholder: "Enter number",
                      disabled: props.editMode,
                    }}
                    validate={errors.tradeLicenseNumber && "error"}
                    rules={{
                      required: "Please enter trade license",
                    }}
                    validMessage={
                      errors.tradeLicenseNumber &&
                      errors.tradeLicenseNumber.message
                    }
                  />
                </Col>

                <Col span={12}>
                  <SelectField
                    label="Emirate"
                    control={control}
                    isRequired={true}
                    fieldname="emirates"
                    validate={errors.emirates && "error"}
                    iProps={{ placeholder: "Select emirate" }}
                    validMessage={errors.emirates && errors.emirates.message}
                    rules={{
                      required: "Please select emirate",
                    }}
                    selectOption={_.map(emiratesListData, (val, key) => ({
                      value: key,
                      label: val,
                    }))}
                  />
                </Col>
              </Row>

              <Row gutter={24}>
                <Col span={12}>
                  <DateField
                    isRequired={true}
                    control={control}
                    label="Issue Date"
                    fieldname="startDate"
                    validate={errors.startDate && "error"}
                    validMessage={errors.startDate && errors.startDate.message}
                    onChange={(e) => {
                      if (endDate && endDate < e) {
                        setEndDate(e);
                        setValue("endDate", null);
                      }
                      if (e < endDate) {
                        setEndDate(dayjs());
                        setValue("endDate", dayjs());
                      }
                      setStartDate(e);
                      setValue("startDate", e);
                    }}
                    rules={{
                      required: "Please enter start date",
                    }}
                    iProps={{
                      format: "DD-MM-YYYY",
                      placeholder: "DD-MM-YYYY",
                    }}
                  />
                </Col>
                <Col span={12}>
                  <DateField
                    control={control}
                    isRequired={true}
                    className="enddate"
                    fieldname="endDate"
                    label="Expiry Date"
                    validate={errors.endDate && "error"}
                    validMessage={errors.endDate && errors.endDate.message}
                    rules={{
                      required: "Please enter end date",
                    }}
                    onChange={(e) => {
                      setEndDate(e);
                      //setValue("endDate", e);
                    }}
                    iProps={{
                      format: "DD-MM-YYYY",
                      placeholder: "DD-MM-YYYY",
                      disabledDate: (current) => current <= dayjs(startDate),
                    }}
                  />
                </Col>
              </Row>
            </>
          )}

          <Row gutter={24} justify="space-between">
            <Col>
              <Button
                className="ag-error theme_grey_btn"
                htmlType="button"
                onClick={() => {
                  !props.selected && reset();
                  props.onCancel();
                }}
              >
                {<BsArrowCounterclockwise />} Back
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button theme_btn" htmlType="submit">
                {<BsPlusCircle />} {_handle_ButtonLabel(props.modalContent)}
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>
  );
};
