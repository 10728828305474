import axios from "../../../../utils/axiosInceptor";
import {
  TICKETS_REVIEWS,
  GET_CLOSED_STATUS,
  GET_INPRGTICKET_STATUS,
  GET_NEWTICKET_STATUS,
  GET_CATEGORIES,
  GET_TECHNICIANS,
  ORGANIZATIONS,
  GET_NEW_TICKETS,
  GET_INPROGRESS_TICKETS,
  GET_COMPLETE_TICKETS,
  GET_NEW_TICKETS_COUNT,
  GET_INROGRESS_TICKETS_COUNT,
  GET_CLOSED_TICKETS_COUNT,
  ALL_ORGANIZATIONS,
  GET_BUSINESS_UNITS,
  GET_BUSINESS_UNIT_TYPES,
} from "./constants";
import { adminService, leaseService } from "../../../../configs/constants";

const ticketsUrl = `${adminService}/admin/getAllTickets/`;
const ticketsCount = `${adminService}/admin/getTicketsCount`;
const ticketsStatusType = `${adminService}/admin/getCaseStatusType`;
const presignedImageUrl = `${leaseService}/leads/download-signed-url`;
const configData = `${adminService}/configData`;

//organisations

export const getBusinessUnitsTypes = (type: string) => async (dispatch) => {
  try {
    const {
      data: { result },
    } = await axios.get(`${configData}?key=${type}`);
    dispatch({
      type: GET_BUSINESS_UNIT_TYPES,
      data: result,
    });
  } catch (e) {
    console.log({ e });
  }
};

export const getOrganizations = (page = 1, filterdata?,setIsLoading=null) =>
  async (dispatch) => {
    setIsLoading && setIsLoading(true)
    const url = `${adminService}/organization?page=${page}&${
      filterdata ? `${filterdata}` : ""
    }`;
     try{
      const {
        data
      } = await axios.get(url);
      
      if (data?.result) {
        dispatch({
          type: ORGANIZATIONS,
          data: data?.result,
        });
        setIsLoading(false)
      }else if(!data?.result && data?.success && data?.code === 200 ){
        setIsLoading(false)
        dispatch({
          type: ORGANIZATIONS,
          data: [],
        });
      }
      
     }catch(erro){
      setIsLoading(false)
     }
  };

export const getAllOrganizations = () => async (dispatch) => {
  const url = `${adminService}/organization/list`;
  const {
    data: { result },
  } = await axios.get(url);
  if (result) {
    dispatch({
      type: ALL_ORGANIZATIONS,
      data: result,
    });
  }
};

export const getBusinessUnits =
  (orgID, param = {}) =>
  async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(`${adminService}/businessUnit/list?orgId=${orgID}`, {
        params: param,
      });
      // console.log("called",result);
      if (result) {
        dispatch({
          type: GET_BUSINESS_UNITS,
          data: result,
        });
      }
    } catch (e) {
      // console.log("Err", e.response.data.error);
      dispatch({
        type: GET_BUSINESS_UNITS,
        data: [],
      });
    }
  };

export const getNewTickets =
  (orgID, record, param = {}) =>
  async (dispatch) => {
    const {
      data: { result },
    } = await axios.get(
      `${ticketsUrl}?organizationId=${orgID}&status=new&totalRecords=${record}`,
      { params: param }
    );
    if (result) {
      dispatch({
        type: GET_NEW_TICKETS,
        data: result,
      });
    }
  };
export const getInprogressTickets =
  (orgID, record, param = {}) =>
  async (dispatch) => {
    const {
      data: { result },
    } = await axios.get(
      `${ticketsUrl}?organizationId=${orgID}&status=inProgress&totalRecords=${record}`,
      { params: param }
    );
    if (result) {
      dispatch({
        type: GET_INPROGRESS_TICKETS,
        data: result,
      });
    }
  };
export const getCompleteTickets =
  (orgID, record, param = {}) =>
  async (dispatch) => {
    const {
      data: { result },
    } = await axios.get(
      `${ticketsUrl}?organizationId=${orgID}&status=completed&totalRecords=${record}`,
      { params: param }
    );
    if (result) {
      dispatch({
        type: GET_COMPLETE_TICKETS,
        data: result,
      });
    }
  };

export const getNewCount =
  (orgID, param = {}) =>
  async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(
        `${ticketsCount}?organizationId=${orgID}&status=new`,
        {
          params: param,
        }
      );

      dispatch({
        type: GET_NEW_TICKETS_COUNT,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
export const getInprogressCount =
  (orgID, param = {}) =>
  async (dispatch) => {
    {
      try {
        const {
          data: { result },
        } = await axios.get(
          `${ticketsCount}?organizationId=${orgID}&status=inProgress`,
          { params: param }
        );

        dispatch({
          type: GET_INROGRESS_TICKETS_COUNT,
          data: result,
        });
      } catch (e) {
        console.log("Err", e);
      }
    }
  };
export const getCompleteCount =
  (orgID, param = {}) =>
  async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(
        `${ticketsCount}?organizationId=${orgID}&status=completed`,
        { params: param }
      );

      dispatch({
        type: GET_CLOSED_TICKETS_COUNT,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };

export const getAllTechnicains = () => async (dispatch) => {
  // const url = `${adminService}/admin/getFacilityManagerTechnicians`;
  // const response = await axios.get(url);
  // dispatch({
  //     type: GET_TECHNICIANS,
  //     data: response?.data.result
  // });
};
export const getAllCategories = () => async (dispatch) => {
  const url = `${adminService}/config/getCategoryRoots`;
  const response = await axios.get(url);
  dispatch({
    type: GET_CATEGORIES,
    data: response?.data.result.rows,
  });
};

export const getNewTicketStatus = () => async (dispatch) => {
  try {
    const {
      data: { result },
    } = await axios.get(`${ticketsStatusType}?status=new`);
    dispatch({
      type: GET_NEWTICKET_STATUS,
      data: result,
    });
  } catch (e) {
    console.log("Err", e);
  }
};
export const getInPrgTicketStatus = () => async (dispatch) => {
  try {
    const {
      data: { result },
    } = await axios.get(`${ticketsStatusType}?status=inProgress`);
    dispatch({
      type: GET_INPRGTICKET_STATUS,
      data: result,
    });
  } catch (e) {
    console.log("Err", e);
  }
};
export const getClosedTicketStatus = () => async (dispatch) => {
  try {
    const {
      data: { result },
    } = await axios.get(`${ticketsStatusType}?status=completed`);
    dispatch({
      type: GET_CLOSED_STATUS,
      data: result,
    });
  } catch (e) {
    console.log("Err", e);
  }
};
export const getPresignedImage = async (mediaArray) => {
  const mediaUrls = [];
  for (let i of mediaArray) {
    const postData = { url: i };
    const {
      data: { result },
    } = await axios.post(presignedImageUrl, postData);
    mediaUrls.push(result?.imageUrl);
  }
  return mediaUrls;
};
export const getTicketReviews = (param) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(`${adminService}/admin/getTicketReviews`, {
        params: { caseId: param },
      });
      dispatch({
        type: TICKETS_REVIEWS,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};
