import React, { FC, useState } from 'react'
import { useForm } from "react-hook-form";
import axios  from '../../../../utils/axiosInceptor';
import {
    Row,
    Col,
    Form,
    Button,
    message
    
} from "antd";
import {BsArrowCounterclockwise } from "react-icons/bs";
import { TextAreaField } from '../../../atoms/FormElement';
import { adminService } from '../../../../configs/constants';

/**
* @author
* @function @
**/
const defaultValues: any = {
    config_setting: "",
};
export default (props) => {

    const {
        control,
        formState: { errors },
        reset,
        handleSubmit,
        setValue,
    } = useForm({
        defaultValues,
    });

   // Function to validate JSON
  const validateJson = (value) => {
    try {
    //   JSON.parse(value);
      return JSON.parse(value);
    } catch (error) {
        message.error("Invalid JSON format. Please fix it before uploading.");
      return false;
    }
  };

    const _submitData = async (url , method, data)=>{
        props?.setIsloading(true)
        axios({
            url,
           method,
           data,
           headers: { application: 4 },
         })
           .then((response) => {
            

           
            if(response?.data?.code === 200){
                message.success("Configuration updated successfully");
                props?.setIsloading(false)
                props.onCancel();
               
            }
        
           })
           .catch((error) => {
             console.log(error)
           });
    }
    const onFinish =  (values) => {
         let data = {};
         let configData = values?.config_setting ? validateJson(values?.config_setting) : null;
         const Url =  `${adminService}/organization/${props.data?.orgId}`;
         const method = "PATCH" ;
         data['configData'] = configData ? configData : {};
         configData && _submitData(Url, method,data);
    }
    
    return (
        <Form
            layout="vertical"
            className="bu_o_form"
            onFinish={handleSubmit(onFinish)}
        >


            <>

                <>
                    <Row gutter={24} style={{marginTop:'20px'}}>
                        <Col span={24}>
                            <TextAreaField
                                isRequired={true}
                                fieldname="config_setting"
                                initValue={props?.data && JSON.stringify(props?.data?.configData, null, 2)}
                                label=""
                                // textAreaHeight={500}
                                setValue={setValue}
                                control={control}
                                valueGot={props?.data && JSON.stringify(props?.data?.configData, null, 2)}
                                rules={{
                                    required: "Field is required",
                                }}
                                iProps={{
                                     placeholder: "update configuration", 
                                     autoSize: { minRows: 10},
                                 
                                    
                                    }}
                     
                                validate={errors.comment && "error"}
                                validMessage={errors.comment && errors.comment.message}
                            />
                        </Col>

                       
                    </Row>


                </>



                <Row gutter={24} justify="space-between">
                    <Col>
                        <Button
                            className="ag-error theme_grey_btn"
                            htmlType="button"
                            onClick={() => {
                             
                                props.onCancel();
                            }}
                        >
                            {<BsArrowCounterclockwise />} Back
                        </Button>
                    </Col>
                    <Col>
                        <Button className="ag-green-button theme_btn" htmlType="submit">
                           
                            Save
                        </Button>
                    </Col>
                </Row>
            </>

        </Form>
    );

}
